import React, {useState, useEffect} from 'react';
import './style.css';
import BoxColumn from './components/BoxColumn.js';

function App() {
  const [scrollDisabled, setScrollDisabled] = useState(true);

  useEffect(() => {
    
    const timer = setTimeout(() => {
      setScrollDisabled(false);
    }, 10);

    return () => clearTimeout(timer);
  }, []);
  

  return (
    <html lang="CA-en">
      <head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, initial-scale=1, maximum-scale=1, user-scalable=no"/>
        <title>The Treehouse</title>
        <link rel="stylesheet" href="style.css"/>
        <link href="https://fonts.googleapis.com/css?family=Inconsolata" rel="stylesheet"/>
        
      </head>
      <body className={scrollDisabled ? 'disable-scroll' : ''}>
        <section className="header">
          <div className="header-banner">    
            <img src={`${process.env.PUBLIC_URL}/images/Page1.png`} alt="Treehouse Logo"/>
            <div className="header-text">
              <h4>Hello world.</h4>
            </div>
            {/* <div className="subheader-text">
              <h7>My name is Clinton, and I am an undergraduate student currently studying Computer Engineering at Simon Fraser University. I may or may not be addicted to gaming.</h7>
            </div> */}
          </div>
        </section> 
        {/* <section className="body">
          <div className="body-image">
            <img src="./images/Page10T.png" alt="Treehouse Logo"/>
            <div className="body-text">
              <h1>My Projects</h1>
              <p>Here are some of the projects I've worked on in the past.</p>
              <div className="fish-feeder">
                <a href="https://github.com/TreeRacks"> <img src="./images/FishFeeder.svg" alt="FishFeeder Logo"/></a>
                <div className="fish-feeder-text">
                  <p><a href="https://github.com/TreeRacks" target ="_blank">FishFeeder69</a></p>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className="body2">
          <div className="combinedImage">
            <img src={`${process.env.PUBLIC_URL}/images/Page2T.png`} alt="SplitImage"/>
            <div className="blog-title">
              <h1>About Me</h1>
            </div>
            <div className="about-me-section">
              
              <div className = "about-me-section-descriptor"> 
                <p>Welcome to the Treehouse!</p>
              
              </div>
              <BoxColumn /> 
              
              
            </div>
          </div> 
        </section>
        
      </body>
      <footer>
        <div className="footer">
          <p>© 2024 Clinton Weng</p>
        </div>
      </footer>
      
    </html>
  );
}

export default App;
