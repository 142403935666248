import React from 'react';
import './BoxColumn.css'; 

function BoxColumn() {
  const [expandedBoxes, setExpandedBoxes] = React.useState({});
  const [additionalImage, setAdditionalImage] = React.useState(null);

  const handleBoxClick = (id) => {
    setExpandedBoxes(prevState => {
      const updatedState = {};
      Object.keys(prevState).forEach(boxId => {
        updatedState[boxId] = (boxId === id) ? !prevState[boxId] : false;
      });
      updatedState[id] = !prevState[id];
      return updatedState;
    });


    const box = boxesData.find(box => box.id === id);
    if (box) {
      additionalImage && additionalImage === box.imagePath ? setAdditionalImage(null) :
      setAdditionalImage(box.imagePath);
    } else {
      setAdditionalImage(null);
    }
  };

  // const handleMouseLeave = (boxId) => {
  //   setExpandedBoxes(prevState => ({
  //     ...prevState,
  //     [boxId]: false
  //   }));
  // };

  const boxesData = [
    { id: 1, imagePath: 'images/Page3.png', thumbnailPath: 'images/Page3_thumb.png'},
    { id: 2, imagePath: 'images/Page4.png', thumbnailPath: 'images/Page4_thumb.png'},
    { id: 3, imagePath: 'images/Page5.png', thumbnailPath: 'images/Page5_thumb.png'},
    { id: 4, imagePath: 'images/Page6.png', thumbnailPath: 'images/Page6_thumb.png'},
    { id: 5, imagePath: 'images/Page7.png', thumbnailPath: 'images/Page7_thumb.png'},
    // Add more box data as needed
  ];

  const textData = [
    { id: 1, text: '', expandedText: 'Who is this?', fullText: "My name is Clinton Weng. I was born and raised in Burnaby, Canada. I am currently studing Computer Engineering at Simon Fraser University. I may or may not be addicted to gaming. I can also quite forgetful sometimes. As a kid I also had staring contests with the sun but I was pretty bad at it." },
    { id: 2, text: '', expandedText: 'What do you like?', fullText: 'When I was little, I wanted to be an astronaut. So, I like astronomy. When I was little, I also liked taking pictures of things, so I like photography. I also played a lot of video games as a kid so I like playing video games.'},
    { id: 3, text: '', expandedText: "Things I've worked on", fullText: 'You are looking at one of the things I made right now. I also made a fish feeder, but none of my fish lived long enough to see my work come to fruition. I also worked as a QA intern at Magnify Digital. You can find out about their work here. '},
    { id: 4, text: '', expandedText: 'Find me!', fullText: ''},
    { id: 5, text: '', expandedText: 'Like these images?', fullText: ''},
    // Add more text data as needed
  ];

  

  return (
    <div className="box-column">
      {boxesData.map((box, index) => (
        <div
          key={box.id}
          className={`box ${expandedBoxes[box.id] ? 'expanded' : ''}`}
          onClick={() => handleBoxClick(box.id)}
          //onMouseLeave={() => handleMouseLeave(box.id)}
          style={{ zIndex: boxesData.length - index }}
        >
            <div key={box.id} className="text-container">
              {!expandedBoxes[box.id] && <p>{textData[index].text}</p>} 
              {expandedBoxes[box.id] && <p>{textData[index].expandedText}</p>}
            </div>
            
            <img className="thumbnail-image" src={`${process.env.PUBLIC_URL}/${box.thumbnailPath}`} alt={textData[index].text} />
          </div>
          
      ))}
      {Object.values(expandedBoxes).some(expanded => expanded) && (
        <div className="full-image-container">
          {boxesData.map((box, index) => (
            expandedBoxes[box.id] &&
            <div 
            key={box.id} 
            className={`full-image-visible`}>
              <div key={box.id} 
                className={`full-image-text-container ${expandedBoxes[box.id] ? 'show-full-image-text' : 'hide-full-image-text'}`}>
                {!expandedBoxes[box.id] && <p>{textData[index].text}</p>} 
                
                {expandedBoxes[box.id] && (
                <p>
                  {index === 2 ? (
                    <>
                      You are looking at one of the things I made right now. I also made a fish feeder, but none of my fish lived long enough to see my work come to fruition. Feel free to take a look at what I mess around with on my <a href="https://github.com/TreeRacks" target="_blank" rel="noreferrer">GitHub</a>.<br/><br/> I also worked as a QA intern at Magnify Digital. You can find out about their work <a href="https://magnifydigital.com" target="_blank" rel="noreferrer">here</a>.
                    </>
                  ) : index === 3 ? (
                    <>
                      Come find me on Discord! If you would like to reach me or send me pictures of round frogs, send a message to: <br/> treeracks <br/> <br/> Feel free to shoot me an email at my student email: <br/> cwa230@sfu.ca. <br/>If I miraculously forget that one, let me know at clintonweng02@gmail.com.
                    </>
                  ) : index === 4 ? (
                    <>
                      These backgrounds were made by me from scratch in Blender and then rendered in Cycles, Blender's own ray-trace rendering engine. If you like what you see, you can take a look at the images in this Drive folder <a href="https://drive.google.com/drive/folders/1u9VkRW_2ZnaFGf4gqhYL0NPRAr-_0Me2?usp=sharing" target="_blank" rel="noreferrer">here</a>. <br/><br/> I sometimes make more of these so feel free to check it out occasionally. Feel free to use these images.
                    </>
                  ) : (
                    textData[index].fullText
                  )}
                </p>
              )}
                
              </div>
              
              <img className={`full-image ${expandedBoxes[box.id] ? 'show-full-image' : 'hide-full-image'}`} src={`${process.env.PUBLIC_URL}/${box.imagePath}`} alt={textData[index].fullText} />
            </div>
          ))}
        </div>
      )}

    </div>
    
  );
}

export default BoxColumn;